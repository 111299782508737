<template>
    <div class="reg">
        <Top name="账号注册" back></Top>
        <div class="login_w">
            <div class="login_form">
                <div class="form_item">
                    <input type="text" v-model="loginForm.phone" placeholder="手机">
                    <span>+86</span>
                </div>
                <div class="form_item">
                    <input type="text" v-model="loginForm.yzm" placeholder="请输入手机验证码">
                    <GetYzmCode :username="loginForm.phone"></GetYzmCode>
                </div>
                <div class="form_item">
                    <input type="password" v-model="loginForm.password" placeholder="请输入密码">
                </div>
            </div>
            <div class="dragverify">
                <DragVerify
                    ref="dragVerify"
                    :isPassing.sync="isPassing2"
                    text="请按住滑块拖动"
                    successText="验证通过"
                    handlerIcon="drag_arrow"
                    successIcon="van-icon van-icon-success"
                    handlerBg="#fff"
                    >
                </DragVerify>
            </div>
            <div class="login_notice">
                <span>账号说明</span>
                <router-link to="accountdesp" class="accountdesp"><van-icon name="question-o" size="1.4rem" /></router-link>
            </div>
            <div class="login_btns">
                <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" @click="regClick">注册并登录</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top'
import DragVerify from '@/components/dragVerify'
import GetYzmCode from '@/components/getYzmCode'

export default {
    name: 'Register',
    components: {
        Top,
        DragVerify,
        GetYzmCode
    },
    data() {
        return {
            loginForm: {
                phone: '',
                yzm: '',
                password: '',
            },
            codeText: '获取',
            disabled: false,
            isPassing2: false,
            yzmCode: ''
        }
    },
    methods: {
        //注册
        regClick() {
            if (this.isPassing2) {
                if (this.loginForm.phone != '' && this.loginForm.password != '' && this.loginForm.yzm != '') {
                    //验证验证码
                    this.$api.register.checkCode({
                        username: this.loginForm.phone,
                        code: this.loginForm.yzm
                    }).then(res => {
                        if (res.code == 200) {
                            this.$api.register.register(
                                {
                                    username: this.loginForm.phone,
                                    password: this.loginForm.password,
                                    code: this.loginForm.yzm,
                                }
                            ).then(res => {
                                if (res.code == 200) {
                                    localStorage.setItem('token', 'Bearer '+ res.data.token);
                                    localStorage.setItem('username', this.loginForm.phone);
                                    this.$router.push('/');
                                }
                            }).catch(err => {
                                console.log(err);
                            });
                        }else{
                            this.$toast({
                                message: '验证码错误！',
                                duration: 1500
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }else{
                    this.$toast({
                        duration: 1500,
                        message: '请先填完输入框'
                    });
                }
            }else{
                this.$toast({
                    duration: 1500,
                    message: '请先拖动滑块'
                });
            }
        },
    }
}
</script>
<style>
.reg .login_form{
    margin: 0 0 6%;
}
</style>
<style>
.login_notice .accountdesp{
    float: left;
    color: #313131;
}
</style>